import {joinList} from "@/Api/Group";
import Storage from "@/util/Storage";
import {joinListType} from "@/Api/Group/indexType";


export function getJoinList(upData:joinListType) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( joinList({userId,token},upData).then(res=>{
        return res.data
    }) )
}
